body {
  margin: 0;
  font-family: "Fira Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Works on Firefox */
* {
  scrollbar-width: thick;
  scrollbar-color: #ff1616 #02020c;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: #222;
}

*::-webkit-scrollbar-thumb {
  background-color: #02020c;
  border-radius: 20px;
  border: 3px solid #222;
}
