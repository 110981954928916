.introFooter {
  display: block;
}

/* util */

.grid {
  margin: 0;
  padding: 0;

  grid-gap: 0px !important;
}

.cell {
  margin: 0;
  padding: 0;

  /* border: 1px solid #020c10; */
}

.neonText {
  color: #ff1616;
  /* text-shadow:
    0 0 7px #ff1616,
    0 0 10px #ff1616; */
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  margin: 20px;

  background: #f7cc7c;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 40px;
  background: red;
  cursor: pointer;
  border: none;
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 40px;
  background: red;
  cursor: pointer;
  border: none;
}

.button {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;

  background: linear-gradient(to bottom left, hsla(39, 88%, 73%, 20%) 50%, hsla(39, 88%, 73%, 40%) 50% 100%),
    linear-gradient(to bottom right, hsla(39, 88%, 73%, 20%) 50%, hsla(39, 88%, 73%, 40%) 50% 100%);
  border-radius: 7px;

  width: 250px;
  padding: 10px;

  color: #6d4c44;
  font-size: 20pt;
}

.button a {
  color: #6d4c44;
  font-size: 20pt;
}

@media (min-width: 1100px) {
  .intro {
    max-width: 1000px;
    border: none;

    margin-left: auto;
    margin-right: auto;
  }

  .description {
    max-width: 800px;
    margin: auto;
    color: #020c10;
  }

  .descriptionBlock {
    margin-top: 50px;
  }

  /* revenue */
  .revenueHeader {
    font-size: 35pt;
    font-weight: bold;
    color: #6d4c44;
  }

  .revenueSubHeader {
    font-size: 25pt;
    font-weight: bold;
    color: #6d4c44;
  }

  .revenueImage {
    object-fit: contain;
    max-height: 1000px;
  }

  .revenueDesc {
    color: #6d4c44;
    height: 100%;
  }

  .revenueDesc h1 {
    font-size: 40pt;
    color: #6d4c44;
  }

  .revenueDesc h2 {
    font-size: 25pt;
    color: #6d4c44;
  }

  .revenueDesc ul {
    list-style-type: disc;
    font-size: 25pt;
  }

  .revenueDesc p {
    font-size: 20pt;
    text-align: left;
    padding-left: 20px;
    color: #6d4c44;
  }

  .revenueCalc {
    font-size: 14pt;
    color: #6d4c44;
    text-decoration: none;
    text-align: left;
  }

  .revenueResult {
    font-size: 16pt;
    padding-bottom: 20px;
  }

  .revenueResult li {
    font-size: 16pt;
    padding: 5px;
  }

  .revenueResult :nth-child(odd) {
    background-color: hsla(39, 88%, 73%, 20%);
  }

  .network {
    margin: auto;
    width: 700px;
    /* background-color: green; */
  }

  .networkBlock {
    padding: 30px;
    font-size: 20pt;
    color: #6d4c44;
  }

  .networkBlockArrow {
    padding: 30px;
    margin-left: 20px;

    font-size: 30pt;
    font-weight: bold;
    color: hsla(39, 88%, 63%, 100%);
  }

  /* Early pay */
  .earlypayImage {
    /* object-fit: contain; */
    height: 500px !important;
  }

  .earlypayHeader {
    font-size: 35pt;
    font-weight: bold;
    color: #6d4c44;
    margin-top: 100px;
  }

  .earlypayDesc {
    color: #6d4c44;
    height: 100%;
  }

  .earlypayDesc h1 {
    font-size: 40pt;
    color: #6d4c44;
  }

  .earlypayDesc h2 {
    font-size: 25pt;
    color: #6d4c44;
  }

  .earlypayDesc ul {
    list-style-type: disc;
    font-size: 25pt;
  }

  .earlypayDesc p {
    font-size: 16pt;
    text-align: left;
    padding-left: 20px;
    color: #6d4c44;
  }

  .earlypayCalc {
    font-size: 14pt;
    color: #6d4c44;
    text-decoration: none;
    text-align: left;

    padding-top: 50px;
    padding-bottom: 20px;
  }

  .earlypayCalc p {
    font-size: 20pt;
  }

  /* po */
  .poImage {
    object-fit: contain;
    max-height: 600px;
  }

  .knob {
    margin: auto;
    max-height: 900px;
  }

  /* iframe */
  .if2 {
    width: 100vw;
    height: 100vh;
    max-width: 1400px;
    max-height: 1200px;

    position: relative;
    left: 0px;
    top: 100px;
    border-radius: 30px;

    -moz-transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    -o-transform: scale(0.7, 0.7);
    -ms-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -o-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
  }

  .menu {
    text-align: left;
  }

  :global(.menu p) {
    color: #ff1616;
    font-size: 10pt !important;
  }

  .menu a {
    color: #020c10;
    font-size: 11pt;
    width: 150px;
  }

  .menu p {
    margin-bottom: 0 !important;
  }

  .content {
    color: #020c10;
    font-size: 16pt;
    text-align: left;
  }
}

@media (max-width: 1100px) and (min-width: 481px) {
  .intro {
    border: none;

    margin-left: auto;
    margin-right: auto;
    max-width: 80vw;
  }

  .description {
    max-width: 80vw;
    margin: auto;
    color: #020c10;
  }

  .descriptionBlock {
    /* margin-top: 50px; */
  }

  /* revenue */
  .revenueHeader {
    font-size: 25pt;
    font-weight: bold;
    color: #6d4c44;
  }

  .revenueSubHeader {
    font-size: 15pt;
    font-weight: bold;
    color: #6d4c44;
  }

  .revenueImage {
    object-fit: contain;
    max-height: 1000px;
  }

  .revenueDesc {
    color: #6d4c44;
    height: 100%;
  }

  .revenueDesc h1 {
    font-size: 20pt;
    color: #6d4c44;
  }

  .revenueDesc h2 {
    font-size: 15pt;
    color: #6d4c44;
  }

  .revenueDesc ul {
    list-style-type: disc;
    font-size: 20pt;
  }

  .revenueDesc p {
    font-size: 15pt;
    text-align: left;
    color: #6d4c44;
  }

  .revenueCalc {
    font-size: 15pt;
    color: #6d4c44;
    text-decoration: none;
    text-align: left;
  }

  .revenueResult {
    font-size: 14pt;
    padding-bottom: 20px;
  }

  .revenueResult li {
    font-size: 12pt;
    padding: 5px;
  }

  .revenueResult :nth-child(odd) {
    background-color: hsla(39, 88%, 73%, 20%);
  }

  .network {
    margin: auto;
    width: 80vw;
  }

  .networkBlock {
    font-size: 10pt;
    color: #6d4c44;
  }

  .networkBlockArrow {
    font-size: 20pt;
    font-weight: bold;
    color: hsla(39, 88%, 63%, 100%);
  }

  /* Early pay */
  .earlypayImage {
    /* object-fit: contain; */
    height: 500px !important;
  }

  .earlypayHeader {
    font-size: 25pt;
    font-weight: bold;
    color: #6d4c44;
    margin-top: 100px;
  }

  .earlypayDesc {
    color: #6d4c44;
    height: 100%;
  }

  .earlypayDesc h1 {
    font-size: 40pt;
    color: #6d4c44;
  }

  .earlypayDesc h2 {
    font-size: 15pt;
    color: #6d4c44;
  }

  .earlypayDesc ul {
    list-style-type: disc;
    font-size: 15pt;
  }

  .earlypayDesc p {
    font-size: 16pt;
    text-align: left;
    padding-left: 20px;
    color: #6d4c44;
  }

  .earlypayCalc {
    font-size: 14pt;
    color: #6d4c44;
    text-decoration: none;
    text-align: left;

    padding-bottom: 20px;
  }

  .earlypayCalc p {
    font-size: 20pt;
  }

  /* po */
  .poImage {
    object-fit: contain;
    max-height: 600px;
  }

  .knob {
    margin: auto;
    max-height: 900px;
  }

  /* iframe */
  .if2 {
    width: 100vw;
    height: 100vh;
    max-width: 1400px;
    max-height: 1200px;

    position: relative;
    left: 0px;
    top: 100px;
    border-radius: 30px;

    -moz-transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    -o-transform: scale(0.7, 0.7);
    -ms-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -o-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
  }

  .menu {
    text-align: left;
  }

  :global(.menu p) {
    color: #ff1616;
    font-size: 10pt !important;
  }

  .menu a {
    color: #020c10;
    font-size: 11pt;
    width: 150px;
  }

  .menu p {
    margin-bottom: 0 !important;
  }

  .content {
    color: #020c10;
    font-size: 16pt;
    text-align: left;
  }
}

@media (max-width: 481px) {
  .intro {
    border: none;

    margin-left: auto;
    margin-right: auto;
    max-width: 80vw;
  }

  .description {
    max-width: 80vw;
    margin: auto;
    color: #020c10;
  }

  .descriptionBlock {
    /* margin-top: 50px; */
  }

  /* revenue */
  .revenueHeader {
    font-size: 25pt;
    font-weight: bold;
    color: #6d4c44;
  }

  .revenueSubHeader {
    font-size: 15pt;
    font-weight: bold;
    color: #6d4c44;
  }

  .revenueImage {
    object-fit: contain;
    max-height: 1000px;
  }

  .revenueDesc {
    color: #6d4c44;
    height: 100%;
  }

  .revenueDesc h1 {
    font-size: 20pt;
    color: #6d4c44;
  }

  .revenueDesc h2 {
    font-size: 15pt;
    color: #6d4c44;
  }

  .revenueDesc ul {
    list-style-type: disc;
    font-size: 20pt;
  }

  .revenueDesc p {
    font-size: 15pt;
    text-align: left;
    color: #6d4c44;
  }

  .revenueCalc {
    font-size: 15pt;
    color: #6d4c44;
    text-decoration: none;
    text-align: left;
  }

  .revenueResult {
    font-size: 14pt;
    padding-bottom: 20px;
  }

  .revenueResult li {
    font-size: 12pt;
    padding: 5px;
  }

  .revenueResult :nth-child(odd) {
    background-color: hsla(39, 88%, 73%, 20%);
  }

  .network {
    margin: auto;
    width: 80vw;
  }

  .networkBlock {
    font-size: 10pt;
    color: #6d4c44;
  }

  .networkBlockArrow {
    font-size: 20pt;
    font-weight: bold;
    color: hsla(39, 88%, 63%, 100%);
  }

  /* Early pay */
  .earlypayImage {
    /* object-fit: contain; */
    height: 500px !important;
  }

  .earlypayHeader {
    font-size: 25pt;
    font-weight: bold;
    color: #6d4c44;
    margin-top: 100px;
  }

  .earlypayDesc {
    color: #6d4c44;
    height: 100%;
  }

  .earlypayDesc h1 {
    font-size: 40pt;
    color: #6d4c44;
  }

  .earlypayDesc h2 {
    font-size: 15pt;
    color: #6d4c44;
  }

  .earlypayDesc ul {
    list-style-type: disc;
    font-size: 15pt;
  }

  .earlypayDesc p {
    font-size: 16pt;
    text-align: left;
    padding-left: 20px;
    color: #6d4c44;
  }

  .earlypayCalc {
    font-size: 14pt;
    color: #6d4c44;
    text-decoration: none;
    text-align: left;

    padding-bottom: 20px;
  }

  .earlypayCalc p {
    font-size: 20pt;
  }

  /* po */
  .poImage {
    object-fit: contain;
    max-height: 600px;
  }

  .knob {
    margin: auto;
    max-height: 900px;
  }

  /* iframe */
  .if2 {
    width: 100vw;
    height: 100vh;
    max-width: 1400px;
    max-height: 1200px;

    position: relative;
    left: 0px;
    top: 100px;
    border-radius: 30px;

    -moz-transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    -o-transform: scale(0.7, 0.7);
    -ms-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
    -o-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
  }

  .menu {
    text-align: left;
  }

  :global(.menu p) {
    color: #ff1616;
    font-size: 10pt !important;
  }

  .menu a {
    color: #020c10;
    font-size: 11pt;
    width: 150px;
  }

  .menu p {
    margin-bottom: 0 !important;
  }

  .content {
    color: #020c10;
    font-size: 16pt;
    text-align: left;
  }
}
