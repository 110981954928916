.footerlink {
  color: #ff1616;
  /* text-shadow:
    0 0 7px #ff1616,
    0 0 10px #ff1616; */

  font-size: 13pt;
}

.footer {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;

  max-width: 1400px;
  padding-bottom: 50px;
}
