.App {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;

  text-align: center;
  /* background-image: url('../assets/background2.jpg'); */
  /* background-image: linear-gradient(to right, #7d51c4, #d848ad, #fd4f78, #f5b43b, #f5f52e); */
  /* background: radial-gradient(circle, rgba(195,34,145,1) 0%, rgba(253,114,45,1) 100%); */
  /* background: linear-gradient(to right, #ed7979 20%, #e2c286 20% 40%, #e2e288 40% 60%, #93e693 60% 80%, #9797e6 80%); */

  background-color: hsl(34, 53%, 82%);
  background-image: repeating-linear-gradient(
      45deg,
      transparent 5px,
      hsla(197, 62%, 11%, 0.5) 5px,
      hsla(197, 62%, 11%, 0.5) 10px,
      hsla(5, 53%, 63%, 0) 10px,
      hsla(5, 53%, 63%, 0) 35px,
      hsla(5, 53%, 63%, 0.5) 35px,
      hsla(5, 53%, 63%, 0.5) 40px,
      hsla(197, 62%, 11%, 0.5) 40px,
      hsla(197, 62%, 11%, 0.5) 50px,
      hsla(197, 62%, 11%, 0) 50px,
      hsla(197, 62%, 11%, 0) 60px,
      hsla(5, 53%, 63%, 0.5) 60px,
      hsla(5, 53%, 63%, 0.5) 70px,
      hsla(35, 91%, 65%, 0.5) 70px,
      hsla(35, 91%, 65%, 0.5) 80px,
      hsla(35, 91%, 65%, 0) 80px,
      hsla(35, 91%, 65%, 0) 90px,
      hsla(5, 53%, 63%, 0.5) 90px,
      hsla(5, 53%, 63%, 0.5) 110px,
      hsla(5, 53%, 63%, 0) 110px,
      hsla(5, 53%, 63%, 0) 120px,
      hsla(197, 62%, 11%, 0.5) 120px,
      hsla(197, 62%, 11%, 0.5) 140px
    ),
    repeating-linear-gradient(
      135deg,
      transparent 5px,
      hsla(197, 62%, 11%, 0.5) 5px,
      hsla(197, 62%, 11%, 0.5) 10px,
      hsla(5, 53%, 63%, 0) 10px,
      hsla(5, 53%, 63%, 0) 35px,
      hsla(5, 53%, 63%, 0.5) 35px,
      hsla(5, 53%, 63%, 0.5) 40px,
      hsla(197, 62%, 11%, 0.5) 40px,
      hsla(197, 62%, 11%, 0.5) 50px,
      hsla(197, 62%, 11%, 0) 50px,
      hsla(197, 62%, 11%, 0) 60px,
      hsla(5, 53%, 63%, 0.5) 60px,
      hsla(5, 53%, 63%, 0.5) 70px,
      hsla(35, 91%, 65%, 0.5) 70px,
      hsla(35, 91%, 65%, 0.5) 80px,
      hsla(35, 91%, 65%, 0) 80px,
      hsla(35, 91%, 65%, 0) 90px,
      hsla(5, 53%, 63%, 0.5) 90px,
      hsla(5, 53%, 63%, 0.5) 110px,
      hsla(5, 53%, 63%, 0) 110px,
      hsla(5, 53%, 63%, 0) 140px,
      hsla(197, 62%, 11%, 0.5) 140px,
      hsla(197, 62%, 11%, 0.5) 160px
    );

  background-size: 100% 100%;

  padding: 10px;

  font-size: 10pt;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  font-family: "Fira Sans";
}

.appContent {
  border-radius: 30px;
  /* height: calc(100vh - 10px); */
  overflow-y: hidden;
  overflow-x: hidden;
}

@media (min-width: 1100px) {
  .appContent {
    background-image: linear-gradient(
      70deg,
      #faba43 5%,
      #f7cc7c 5% 10%,
      #ffe2ad 10% 15%,
      #ffeed1 15% 20%,
      #fff4db 20% 30%,
      #fff7e7 30% 40%,
      #fffbf5 40% 82%,
      #ffffff 82% 100%
    );
  }
}

@media (max-width: 1100px) {
  .App {
    padding: 0px;
  }

  .appContent {
    border-radius: 0px;

    background-image: linear-gradient(
      70deg,
      #faba43 5%,
      #f7cc7c 5% 10%,
      #ffe2ad 10% 15%,
      #ffeed1 15% 20%,
      #fff4db 20% 30%,
      #fff7e7 30% 40%,
      #fffbf5 40% 82%,
      #ffffff 82% 100%
    );
  }
}
